import React from 'react';
import { find, isEmpty } from 'lodash';
import { navigate } from 'gatsby';
import { connect, ConnectedProps } from 'react-redux';
import { Spinner } from 'react-activity';
import { VideoPlayer } from 'src/components/shared';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import Selectors from 'src/state/root-selectors';

import 'src/styles/video-player.scss';

export interface VideoPageParams {
    slug: string;
}

export interface VideoPageProps {
    params: VideoPageParams;
}

const connector = connect((state: IState) => {
    return {
        allSeries: ContentSelectors.series.list(state),
        entitled: Selectors.auth.entitled(state),
    };
});

const VideoPage = ({
    entitled,
    params,
    allSeries,
}: VideoPageProps & ConnectedProps<typeof connector>) => {
    const { slug } = params;
    const loading = isEmpty(allSeries);
    const series = find(allSeries, (w) => w.fields.slug === slug);

    if (loading) {
        return (
            <div className="video-placeholder-container">
                <Spinner size={40} speed={0.75} />
            </div>
        );
    }

    if (!series) {
        navigate('/404');
        return <div className="video-placeholder-container" />;
    }

    return <VideoPlayer entitled={entitled} videoId={series?.sys.id} type="series" />;
};

export default connector(VideoPage);
